

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-redaction-analyse-convention-actionnaires-quebec.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost49 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Avocat pour la rédaction ou l’analyse de convention entre actionnaires - Soumissions Avocat"
        description="Une société par actions est une entité pour le moins complexe. De nombreuses structures administratives doivent être mises en place pour assurer son fonctionnement, ce qui implique un important lot de services juridiques. En effet, comme une compagnie est la propriété de ses actionnaires et que ceux-ci peuvent être très LIRE PLUS"
        image={LeadImage}>
        <h1>Avocat pour la rédaction ou l’analyse de convention entre actionnaires</h1>

					
					
						<p>Une société par actions est une entité pour le moins complexe. De nombreuses structures administratives doivent être mises en place pour assurer son fonctionnement, ce qui implique un important lot de services juridiques. En effet, comme une compagnie est la propriété de ses actionnaires et que ceux-ci peuvent être très nombreux, il faut prévoir les règles de jeu avant que l’aventure ne commence.</p>
<p><StaticImage alt="avocat-redaction-analyse-convention-actionnaires-quebec." src="../images/avocat-redaction-analyse-convention-actionnaires-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Cela impliquera donc nécessairement de rédiger une convention d’actionnaires afin de mettre en place les divers mécanismes de vente d’actions, de transfert et d’autres changements au niveau de la propriété de ces titres. Mais saisissez-vous bien ce qu’est une convention entre actionnaires et l’importance d’en faire rédiger une par un juriste expert dans le domaine? Si ce n’est pas votre cas, n’ayez crainte, car nous expliquerons les dessus-dessous de ce document essentiel au bon fonctionnement de votre compagnie!</p>
<h2><strong>Qu’est-ce qu’une convention d’actionnaires?</strong></h2>
<p>Pour faire simple, la convention entre actionnaires est le document qui régit les droits des actionnaires les uns envers les autres et envers les titres qu’ils détiennent. Elle prévoit donc les règles générales de la société, son fonctionnement et sa structure, en plus de stipuler les devoirs et obligations que les actionnaires ont les uns envers les autres.</p>
<p>Si une telle convention est toujours recommandée, peu importe le type ou la taille d’une entreprise, elle devient parfois obligatoire pour les compagnies comprenant un important nombre d’actionnaires afin d’éviter d’importantes mésententes.</p>
<p>C’est d’ailleurs l’utilité principale de la convention : prévoir la marche à suivre lors de situations susceptibles d’arriver au cours de l’exploitation de l’entreprise, tels que :</p>
<p><strong>-Un actionnaire souhaite vendre sa part</strong></p>
<p><strong>-Un actionnaire décède</strong></p>
<p><strong>-Un actionnaire déclare faillite</strong></p>
<p>D’ailleurs, le rachat des actions d’un autre partenaire est une procédure qu’il faut absolument inclure dans une convention, puisqu’elle peut devenir une source de conflit. En plus des principales marches à suivre qu’il faut inclure dans la convention, il est aussi judicieux d’inclure d’autres clauses plus détaillées!</p>
<p>Mais concrètement, quelles sont les informations qui doivent apparaître dans une convention dument rédigée? Voici les questions auxquelles votre convention doit absolument avoir réponse :</p>
<p><strong>-Qui sont les actionnaires?</strong></p>
<p><strong>-Quelles sont les parts de chacun?</strong></p>
<p><strong>-Quelles sont les tâches des actionnaires?</strong></p>
<p><strong>-Que se passe-t-il en cas de faillite/décès/disparition d’un actionnaire?</strong></p>
<p><strong>-Signature et date doivent apparaître à la fin de la convention</strong></p>
<p>C’est donc un important volume d’informations qui doivent apparaître dans un document souvent négligé par les petites compagnies. Vous voyez donc que l’intervention d’un juriste qualifié est une expertise qui vous ferait grandement profiter.</p>
<p>De plus, la convention d’actionnaires sert à désigner les règles relatives à la composition et la gestion du conseil d’administration! Cela n’est pas un aspect à prendre à la légère, puisque c’est ce conseil qui prend les décisions sur une base quotidienne. Étant investi d’un tel pouvoir, il est normal qu’il fasse l’objet de disputes fréquentes, ce qui explique pourquoi son fonctionnement doit être clairement établi dans la convention d’actionnaires!</p>
<h2><strong>Pourquoi est-elle essentielle au bon fonctionnement d’une compagnie?</strong></h2>
<p>Parce qu’en affaires comme à la guerre, les amis ne sont pas toujours très nombreux! Il faut donc que tous les partis impliqués soient protégés, mais surtout, qu’ils connaissant leurs droits et obligations vis-à-vis l’entreprise.</p>
<p>C’est d’ailleurs l’utilité première d’un avocat des affaires spécialisé dans la rédaction de conventions d’actionnaires. Il analysera votre entreprise, cernera ses besoins uniques et inclura les clauses pertinentes à l’exploitation de votre entreprise. Voici les quelques clauses populaires qui pourraient être pertinentes dans votre cas :</p>
<p><StaticImage alt="convention-actionnaires-clauses-avocat-entreprise-compagnie." src="../images/convention-actionnaires-clauses-avocat-entreprise-compagnie.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Clause de premier refus : </strong>Aussi appelée un droit de préemption, la clause de premier refus, lorsqu’elle est incluse dans une convention entre actionnaires, forcera ces derniers à offrir leurs parts de l’entreprise à leurs coactionnaires avant de tenter de les vendre à un tiers. Cela a pour but de garder les parts de l’entreprise entre les mains des actionnaires présents dans la compagnie avant d’inclure des tiers n’ayant rien à voir avec celle-ci.</p>
<p><strong>Clause de confidentialité : </strong>Bien qu’un devoir de loyauté envers l’entreprise existe indépendamment de l’inclusion d’une telle clause, prévoir un devoir confidentialité stipulera clairement et sans équivoque la responsabilité des anciens actionnaires de tenir leur langue. Ils n’auront donc pas le droit d’utiliser l’information de l’entreprise pour aider votre compétition. D’ailleurs, la clause de concurrence va presque toujours de concert avec la clause de confidentialité!</p>
<p><strong>Clause d’assurance : </strong>Un actionnaire qui tombe malade et qui devient incapable d’assumer ses fonctions au sein de l’entreprise peut couter cher. C’est pourquoi bien des compagnies choisissent de forcer les actionnaires à souscrire à une assurance invalidité pour assurer les intérêts de la compagnie. Quoi qu’un peu plus téméraire, une telle clause peut même établir une durée maximale d’invalidité avant que l’actionnaire ne soit forcé de vendre ses parts.</p>
<p><strong>Clause d’entraînement : </strong>La clause d’entrainement sert principalement à protéger les intérêts des actionnaires minoritaires. En principe, lorsqu’un actionnaire majoritaire décide de vendre ses parts à un tiers, l’actionnaire minoritaire gagne le droit de lui aussi vendre ses parts, et ce, aux mêmes conditions que l’actionnaire majoritaire. C’est pourquoi on l’appelle la clause d’entraînement.</p>
<p><strong>Clause de non-concurrence :</strong> La clause de non-concurrence est un classique du monde des affaires. Elle sert à empêcher un actionnaire, un employé ou un gestionnaire de quitter la compagnie inopinément pour aller chez un compétiteur. Elle est surtout présente dans les milieux compétitifs ou dans les secteurs où l’information traitée est d’une certaine sensibilité. Toutefois, la loi prévoit que la clause de non-concurrence doit être limitée en 3 aspects afin de minimiser l’impact sur la vie de la personne visée :</p>
<p>-La durée : Vous devez prévoir un délai qui se justifie par la protection des intérêts de votre compagnie et des informations que vous voulez protéger.</p>
<p>-La nature du travail : Vous devez définir précisément le type de travail que votre actionnaire ou employé ne peut effectuer chez un concurrent.</p>
<p>-Le territoire : Vous devez établir un rayon sur lequel l’actionnaire ne peut affecter son travail. Rien ne sert d’établir un territoire déraisonnable, car un  juge ne l’acceptera pas et la clause tombera!</p>
<p>Prenez bien note de ceci avant d’inclure une clause de non-concurrence : si celle-ci devait faire l’objet d’une analyse par un tribunal, ce sera le rôle du juge de décider si la clause est proportionnée ou si elle est abusive. Il analysera chacun de ces trois aspects et si un seul d’entre eux est considéré abusif, le juge fera tomber la clause au complet. Votre actionnaire sera donc libre d’aller travailler où bon lui semble! Encore une autre bonne raison de laisser un avocat rédiger votre convention d’actionnaires!</p>
<p><strong>Clause « Shotgun » : </strong>Vous l’aurez deviné à la lecture de son nom, la clause Shotgun n’est pas pour les doux. Son fonctionnement est quelque peu particulier : si un actionnaire offre d’acheter les actions d’un autre des actionnaires et que ce dernier refuse d’acheter les actions, il devra lui vendre les siennes aux mêmes conditions que l’offre du premier actionnaire. C’est donc véritable un duel de cowboys qui se produit entre les actionnaires impliqués.</p>
<p>Mais pourquoi prévoir une clause si rocambolesque? Parce qu’elle évite d’éterniser les disputes entre actionnaires! En effet, une telle dispute peut prendre des mois à régler si personne n’intervient. C’est donc le rôle de la clause shotgun : il suffit que l’un des actionnaires ne « tire » en premier, ce qui implique cependant le risque de se faire racheter ses parts par l’autre actionnaire.</p>
<h2><strong>Pour quel type d’entreprise faut-il rédiger une convention d’actionnaires?</strong></h2>
<p>Seule une compagnie (aussi appelée une société par actions) a des actionnaires. C’est donc la seule structure d’entreprise qui nécessite la rédaction d’une convention d’actionnaires. Effectivement, comme l’entreprise est la propriété d’un seul individu, celui-ci n’a pas beaucoup de relations à gérer autre qu’avec lui-même, surtout qu’il n’a pas de comptes à rendre à personne.</p>
<p><StaticImage alt="rediger-convention-actionnaires-types-entreprises-compagnie" src="../images/rediger-convention-actionnaires-types-entreprises-compagnie.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Qu’en est-il de la société en nom collectif?</strong> Un tel partenariat nécessite bel et bien un contrat, mais il est bien différent de la convention d’actionnaires, car une telle société n’émet pas d’actions; son fonctionnement interne est donc différent pour cette même raison.</p>
<p>Toutefois, un partenariat de ce genre nécessite la rédaction d’un contrat de société en nom collectif. Celui-ci établira les droits, obligations et l’étendue de la responsabilité légale de chaque associé vis-à-vis des autres. D’ailleurs, comme cette société n’a pas de structure prédéfinie, il faudra prévoir les responsabilités des participants dans ce même contrat.</p>
<p>Vous nous aurez venu venir, la rédaction de ce contrat nécessite également les services d’un avocat spécialisé en droit des affaires ou commercial! De plus, certaines sociétés par actions de petite taille choisissent de ne pas rédiger de convention d’actionnaires. C’est notamment le cas lorsque les actionnaires se connaissent déjà avant de se lancer en affaires.</p>
<p>Toutefois, malgré la présence d’un esprit de camaraderie, une dispute d’affaires peut vite faire tourner ces bonnes relations en conflit. C’est d’ailleurs la raison d’être de la convention d’actionnaires : éviter la chicane. Lorsque les règles sont claires pour tout le monde, personne n’a de raison de se plaindre de quelconque injustice, du moins, en théorie!</p>
<h2><strong>Doit-elle absolument être rédigée par un avocat?</strong></h2>
<p>Une convention d’actionnaires n’est ni plus ni moins qu’un contrat. Ainsi, comme la majorité des contrats, elle n’a pas à être rédigée par une personne en particulier. Vous n’êtes donc pas obligés d’engager un avocat, mais vous comprendrez que la complexité d’un tel document ne vous laisse que peu d’options. Même s’il est vrai que des modèles préfabriqués existent sur internet, ceux-ci ne sont pas adaptés à la réalité bien particulière de votre entreprise.</p>
<p>En effet, en plus d’être un document hautement personnalisable, la rédaction d’une convention d’actionnaires nécessite les conseils d’un expert juriste. Vous devriez donc faire appel à un avocat ou à un notaire œuvrant dans le domaine corporatif. Ces derniers comprennent la réalité dans laquelle vous vous trouvez et donc les besoins que vous devez combler.</p>
<p>De plus, ces professionnels du droit savent s’entourer d’experts provenant de domaines connexes afin de vous offrir des services aussi complets que possible. Par exemple, un planificateur financier ou un fiscaliste pourrait être appelé à donner son opinion sur les aspects d’ordre monétaire concernant votre convention d’actionnaires.</p>
<p>Bref, si vous cherchez à sauver des coûts en rédigeant une convention d’actionnaires à la va-vite, vous vous tirerez dans le pied le jour où un conflit surviendra et que vous serez coincé dans une bataille légale avec des coactionnaires mécontents. Vous ne sauverez pas de frais d’avocats en pareilles circonstances, cela est certain! C’est pourquoi il vaut mieux investir tout de suite pour des conseils juridiques de qualité menant à la rédaction d’une convention d’actionnaires à toute épreuve!</p>
<p><strong>Combien vous couteront les services d’un avocat pour rédiger une convention?</strong> Tout dépend de la complexité du dossier qui lui est soumis! Vous devez toutefois garder en tête que les avocats sont des professionnels extrêmement qualifiés qui chargent un taux horaire.</p>
<p>En règle générale, pour une entreprise de taille moyenne, vous vous en sortirez avec une facture légèrement au-dessus de 1000$. Cependant, ce chiffre peut être nettement plus élevé pour des grandes compagnies ayant un important chiffre d’affaires, tout comme il peut être nettement plus bas pour les entreprises ne comptant que quelques actionnaires. Rappelez-vous toutefois qu’il s’agit d’un investissement qui vous évitera bien des mots de tête dans le futur!</p>
<h2><strong>Trouvez un avocat pour rédiger une convention d’actionnaires sans tarder!</strong></h2>
<p>Si vous êtes dans un processus d’incorporation et que vous avez besoin d’un avocat pour rédiger une convention d’actionnaires, nous pouvons vous aider à trouver le juriste qu’il vous faut! Même chose pour les gestionnaires d’entreprise qui songent à faire modifier la convention existante!</p>
<p><StaticImage alt="engager-avocat-redaction-analyse-convention-compagnie" src="../images/engager-avocat-redaction-analyse-convention-compagnie.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Bref, pour tous vos besoins en matière d’analyse, de rédaction ou de modification de convention entre actionnaires, Soumissions Avocat est la référence dans le milieu!</p>
<p><strong>Tout ce que vous avez à faire, c’est de remplir le formulaire en bas de page pour recevoir gratuitement 3 soumissions gratuites d’avocats du milieu des affaires.</strong></p>
<p><strong>Rien ne sert d’hésiter, nous faisons affaire avec des avocats de renom qui vous offriront un service de qualité, et ce, au meilleur prix!</strong></p>
    </SeoPage>
)
export default BlogPost49
  